import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Vault
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHg9IjMiIHk9IjMiIHJ4PSIyIiAvPgogIDxjaXJjbGUgY3g9IjcuNSIgY3k9IjcuNSIgcj0iLjUiIC8+CiAgPHBhdGggZD0ibTcuOSA3LjkgMi43IDIuNyIgLz4KICA8Y2lyY2xlIGN4PSIxNi41IiBjeT0iNy41IiByPSIuNSIgLz4KICA8cGF0aCBkPSJtMTMuNCAxMC42IDIuNy0yLjciIC8+CiAgPGNpcmNsZSBjeD0iNy41IiBjeT0iMTYuNSIgcj0iLjUiIC8+CiAgPHBhdGggZD0ibTcuOSAxNi4xIDIuNy0yLjciIC8+CiAgPGNpcmNsZSBjeD0iMTYuNSIgY3k9IjE2LjUiIHI9Ii41IiAvPgogIDxwYXRoIGQ9Im0xMy40IDEzLjQgMi43IDIuNyIgLz4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjEyIiByPSIyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/vault
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Vault = createLucideIcon('Vault', [
  ['rect', { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' }],
  ['circle', { cx: '7.5', cy: '7.5', r: '.5', key: '1x97lo' }],
  ['path', { d: 'm7.9 7.9 2.7 2.7', key: 'hpeyl3' }],
  ['circle', { cx: '16.5', cy: '7.5', r: '.5', key: '1kog09' }],
  ['path', { d: 'm13.4 10.6 2.7-2.7', key: '264c1n' }],
  ['circle', { cx: '7.5', cy: '16.5', r: '.5', key: '14ln9z' }],
  ['path', { d: 'm7.9 16.1 2.7-2.7', key: 'p81g5e' }],
  ['circle', { cx: '16.5', cy: '16.5', r: '.5', key: '1jvjsw' }],
  ['path', { d: 'm13.4 13.4 2.7 2.7', key: 'abhel3' }],
  ['circle', { cx: '12', cy: '12', r: '2', key: '1c9p78' }],
]);

export default Vault;
